import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
     path : '/',
     name : 'home',
     component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
   }
]

//component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') / name /path

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
