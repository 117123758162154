import Vue from 'vue'
import App from './App.vue'
import router from './router'
import common from './sharer/common'
import './assets/css/style.css'
import './assets/css/app.scss'
/////////////////
import VueBarcodeScanner from 'vue-barcode-scanner'

import jQuery from 'jquery'

window.$ = window.jQuery = jQuery

import 'bootstrap'
import 'popper.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLockOpen,faPlus,faTrash,faUserEdit,faPen,faPrint,faPowerOff,faCheck,faTimes,faRecycle,faFileInvoice,faBoxes,faMailBulk,faPhone,faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faLockOpen)
library.add(faPlus)
library.add(faTrash)
library.add(faUserEdit)
library.add(faPen)
library.add(faPrint)
library.add(faPowerOff)
library.add(faCheck)
library.add(faTimes)
library.add(faRecycle)
library.add(faFileInvoice)
library.add(faBoxes)
library.add(faMailBulk)
library.add(faPhone)
library.add(faAddressCard)
Vue.component('ic', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.mixin(common)
Vue.use(VueBarcodeScanner)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
