<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  async created()
  {
    document.title = await this.title()
  }
}
</script>


