import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
export default{
    data : ()=>{
        return{
            path : 'https://bazarland.net/materiel/public/api/',
            tit : 'sav',
            //http://localhost/ker/learn%20php/materiel/public/api/
            //https://bazarland.net/materiel/public/api/
        }
    },
    methods : {
       async statu(msg,type)
        {
            return await Vue.$toast.open({
                message: msg,
                type: type,
            });
        },
        link()
        {
            return this.path
        },
        title()
        {
            return this.tit
        }
    }
}